import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Home from './pages/app';
import DowloadApp from './pages/download-app';

const getQueryParam = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

const setCookie = (name, value, days, domain) => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = `expires=${date.toUTCString()}`;
  const cookieDomain = domain ? `domain=${domain};` : '';
  document.cookie = `${name}=${value};${expires};${cookieDomain}path=/`;
};
const Redirect = () => {
  const currentPath = window.location.pathname;
  const trimmedPath = currentPath.startsWith('/') ? currentPath.substring(1) : currentPath;
  const currentOrigin = window.location.origin;
  const redirectUrl = `${currentOrigin}?code=${trimmedPath}`;
  window.location.replace(redirectUrl);
  return null;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>

      <Route path="/" element={<Home />} />
      <Route path="/download-app" element={<DowloadApp />} />
      <Route
        path="*"
        element={<Redirect />}
      />
    </>
  )
);




const SaveCode = ({ children }) => {
  useEffect(() => {
    const code = getQueryParam('code');
    if (code) {
      setCookie('ref_code', code, 7, '.ikigaicoach.net');
    }
  }, []);

  return children;
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SaveCode>
      <RouterProvider router={router} />
    </SaveCode>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
