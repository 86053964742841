import { useEffect, useState } from 'react';
import ChoiceTypeLearningComponent from '../../components/ChoiceTypeLearning';
import FAQComponent from '../../components/FAQ';
import FeedbackComponent from '../../components/Feedback';
import TrendingComponent from '../../components/Trending';
import axios from 'axios';
import ModalComponent from '../../components/ModalPreview';
import FooterComponent from '../../components/Footer';
import HeaderComponent from '../../components/Header';

function Home() {
  const [firstCourse, setFirstCourse] = useState({})
  const [isOpen, setIsOpen] = useState(false);

  const [currentLang, setCurrentLang] = useState(document.documentElement.lang);
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        // pageLanguage: "vi",
        autoDisplay: true
      },
      "google_translate_element"
    );
  };
   useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
    useEffect(() => {
      const intervalId = setInterval(() => {
        // Kiểm tra giá trị hiện tại của thuộc tính lang
        const lang = document.documentElement.lang;
        if (lang !== currentLang) {
          setCurrentLang(lang);
          console.log('Giá trị của thuộc tính lang đã thay đổi thành: ', lang);
          // Thực hiện các xử lý khác tại đây khi lang thay đổi
        }
      }, 1000); // Kiểm tra mỗi giây
  
      return () => {
        // Xóa interval khi component bị unmount
        clearInterval(intervalId);
      };
    }, [currentLang]);
  useEffect(() => {
    axios
    .post(`https://api.ikigai.ikigroup.vn/api/course/list`,{
      sort_by: "createdAt",
      order_by: 'DESC'
    }, {
      headers: {
        "X-Authorization": localStorage.getItem("loginStatus") || "",
      },
    })
    .then((res) => {
      // console.log(res.data);
      setFirstCourse(res.data[0])
    });
  }, [])
  return (
   <>
   {
    currentLang == 'ja' ? (
      <link rel="stylesheet" href='./ja-font.css'/>
    ) : null
   }
      <div id="google_translate_element" style={{display: 'none'}}></div>
      <title>Ikigai Coach</title>

    <div className="mc-page ">
    <HeaderComponent currentLang={currentLang} />
    <ChoiceTypeLearningComponent />

      <>
  <section className="container mc-my-10" data-testid="featured-instructor">
    <div className="mc-text--center">
      <h2 className="mc-text-h1 mc-mt-11 mc-mb-10">
      Gặp gỡ những người giỏi nhất <br />
      Trong lĩnh vực của bạn.
      </h2>
      <div>
        <div tabIndex={-1} style={{ width: "100%", display: "inline-block" }}>
          <div className="d-none d-md-block">
          <div className="mc-py-10 mc-tile mc-tile--21x9">
                <div className="mc-tile__content content">
                  <div
                    color="0, 0, 0"
                    className="mc-tile__component mc-tile-overlay mc-tile-overlay--gradient-right "
                    style={{
                      background:
                        "linear-gradient(to left, rgb(0, 0, 0) 12%, rgba(0, 0, 0, 0.9) 20%, rgba(0, 0, 0, 0) 45%) center center no-repeat"
                    }}
                  />
                  <span
                    style={{
                      boxSizing: "border-box",
                      display: "block",
                      overflow: "hidden",
                      width: "initial",
                      height: "initial",
                      background: "none",
                      opacity: 1,
                      border: 0,
                      margin: 0,
                      padding: 0,
                      position: "absolute",
                      inset: 0
                    }}
                  >
                    <img
                      alt=""
                      image="[object Object]"
                      data-testid="instructor-image"
                      src={firstCourse?.avatar?.media_url}
                      decoding="async"
                      data-nimg="fill"
                      className="mc-tile-image mc-corners--rounded"
                      sizes="100vw"
                     style={{
                        position: "absolute",
                        inset: 0,
                        boxSizing: "border-box",
                        padding: 0,
                        border: "none",
                        margin: "auto",
                        display: "block",
                        width: 0,
                        height: 0,
                        minWidth: "100%",
                        maxWidth: "100%",
                        minHeight: "100%",
                        maxHeight: "100%"
                      }}
                    />
                    <noscript />
                  </span>
                  <div className="mc-tile__component mc-tile-caption mc-tile-caption--x-right mc-tile-caption--y-center">
                    <div className="mc-tile-caption__content mc-p-3">
                      <div className="mc-text--center d-inline-block">
                        <span className="mc-badge mc-text-x-small mc-badge--default mx-text--bold justify-self-center mc-text--capitalize mc-mb-4">
                          new
                        </span>
                        <div className="mc-mt-4">
                          <span
                            style={{
                              boxSizing: "border-box",
                              display: "inline-block",
                              overflow: "hidden",
                              width: "initial",
                              height: "initial",
                              background: "none",
                              opacity: 1,
                              border: 0,
                              margin: 0,
                              padding: 0,
                              position: "relative",
                              maxWidth: "100%"
                            }}
                          >
                            <span
                              style={{
                                boxSizing: "border-box",
                                display: "block",
                                width: "initial",
                                height: "initial",
                                background: "none",
                                opacity: 1,
                                border: 0,
                                margin: 0,
                                padding: 0,
                                maxWidth: "100%",

                                  fontSize: 17,
                                  fontWeight: '600'
                              }}
                            >
                              <img
                                alt=""
                                aria-hidden="true"
                                src="data:image/svg+xml,%3csvg%20
                                                                                        xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27182%27%20height=%2715.94160583941606%27/%3e"
                                style={{
                                  display: "block",
                                  maxWidth: "100%",
                                  width: "initial",
                                  height: "initial",
                                  background: "none",
                                  opacity: 1,
                                  border: 0,
                                  margin: 0,
                                  padding: 0,
                                  fontSize: 17,
                                  fontWeight: '600'
                                }}
                              />
                            </span>
                           <h3 style={{
                           
                                  fontSize: 17,
                                  fontWeight: '600'
                           }}>{firstCourse?.title}</h3>
                            <noscript />
                          </span>
                        </div>
                        <span
                          className="mc-mt-4 mc-text--2-lines mc-text-medium mc-text-light mc-opacity--hinted mc-text--center"
                          style={{ maxWidth: 400 }}
                        >
                         {firstCourse?.description}
                        </span>
                        <button
                          className="c-button c-button--play c-button--md mc-mb-3 mc-mt-6"
                          onClick={() => setIsOpen(true)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={25}
                            fill="none"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            role="img"
                            className="mc-icon mc-icon--md mc-mr-2"
                          >
                            <path
                              fill="currentColor"
                              d="M7.791 2.695a1.25 1.25 0 0 0-1.92 1.055v17.5a1.25 1.25 0 0 0 1.92 1.055l13.75-8.75a1.25 1.25 0 0 0 0-2.11z"
                            />
                          </svg>
                          Watch Trailer{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <ModalComponent video={firstCourse?.media_id?.media_url} setIsOpen={setIsOpen} isOpen={isOpen} />
          <div className="d-block d-md-none">
           
              <div className="mc-py-10 mc-tile mc-tile--2x3">
                <div className="mc-tile__content content">
                  <div
                    color="0, 0, 0"
                    className="mc-tile__component mc-tile-overlay mc-tile-overlay--gradient-bottom "
                    style={{
                      background:
                        "linear-gradient(to top, rgb(0, 0, 0) 0px, rgba(0, 0, 0, 0.6) 25%, rgba(0, 0, 0, 0) 45%) center center no-repeat"
                    }}
                  />
                  <span
                    style={{
                      boxSizing: "border-box",
                      display: "block",
                      overflow: "hidden",
                      width: "initial",
                      height: "initial",
                      background: "none",
                      opacity: 1,
                      border: 0,
                      margin: 0,
                      padding: 0,
                      position: "absolute",
                      inset: 0
                    }}
                  >
                    <img
                      alt=""
                      image="[object Object]"
                      data-testid="instructor-image"
                      src={firstCourse?.avatar?.media_url}
                      decoding="async"
                      data-nimg="fill"
                      className="mc-tile-image mc-corners--rounded"
                      style={{
                        position: "absolute",
                        inset: 0,
                        boxSizing: "border-box",
                        padding: 0,
                        border: "none",
                        margin: "auto",
                        display: "block",
                        width: 0,
                        height: 0,
                        minWidth: "100%",
                        maxWidth: "100%",
                        minHeight: "100%",
                        maxHeight: "100%"
                      }}
                    />
                    <noscript />
                  </span>
                  <div className="mc-tile__component mc-tile-caption mc-tile-caption--x-center mc-tile-caption--y-bottom">
                    <div className="mc-tile-caption__content mc-p-3">
                      <div className="mc-text--center d-inline-block">
                        <span className="mc-badge mc-text-x-small mc-badge--default mx-text--bold justify-self-center mc-text--capitalize mc-mb-4">
                          new
                        </span>
                        <div className="mc-mt-4">
                          <span
                            style={{
                              boxSizing: "border-box",
                              display: "inline-block",
                              overflow: "hidden",
                              width: "initial",
                              height: "initial",
                              background: "none",
                              opacity: 1,
                              border: 0,
                              margin: 0,
                              padding: 0,
                              position: "relative",
                              maxWidth: "100%"
                            }}
                          >
                            <span
                              style={{
                                boxSizing: "border-box",
                                display: "block",
                                width: "initial",
                                height: "initial",
                                background: "none",
                                opacity: 1,
                                border: 0,
                                margin: 0,
                                padding: 0,
                                maxWidth: "100%"
                              }}
                            >
                             
                            </span>
                            <h3 style={{
                           
                           fontSize: 17,
                           fontWeight: '600'
                    }}>{firstCourse?.title}</h3>
                          </span>
                        </div>
                        <div
                          className="mc-nameplate mc-nameplate--small"
                          data-testid="nameplate"
                        >
                          <div className="mc-nameplate__inner">
                            <div className="NameplateImage_container__pWC1H">
                         
                            </div>
                          </div>
                        </div>
                        <span
                          className="mc-mt-4 mc-text--2-lines mc-text-medium mc-text-light mc-opacity--hinted mc-text--center"
                          style={{ maxWidth: 400 }}
                        >
                         {firstCourse?.description}
                         
                        </span>
                        <button
                          className="c-button c-button--play c-button--md mc-mb-3 mc-mt-6"
                            onClick={() => setIsOpen(true)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={25}
                            fill="none"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            role="img"
                            className="mc-icon mc-icon--md mc-mr-2"
                          >
                            <path
                              fill="currentColor"
                              d="M7.791 2.695a1.25 1.25 0 0 0-1.92 1.055v17.5a1.25 1.25 0 0 0 1.92 1.055l13.75-8.75a1.25 1.25 0 0 0 0-2.11z"
                            />
                          </svg>
                          Watch Trailer{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <TrendingComponent />
  <section
    className="container mc-my-12"
    data-testid="start-journey-section"
    aria-label="Start your journey"
  >
    <div className="d-flex flex-column">
      <div className="d-block d-md-none">
        <p className="mc-text--center mc-pt-10 mc-pb-8 mc-text-h1">
        Bắt đầu hành trình của bạn ngay hôm nay.
        </p>
        <div className="mc-mb-8 mc-text--center">
          <div className="flex-column flex-md-row justify-content-center d-flex">
            <p className="mc-text--center mc-opacity--hinted">
            Chọn từ 3 gói dịch vụ.
            </p>
            <div className="justify-content-center d-flex flex-row">
              <p className="mc-pl-1 mc-text--center mc-opacity--hinted">
               Bắt đầu chỉ với
              </p>
              <div className="tracking-container ">
                <p className="mc-pl-1 mc-text--center mc-text--bold">
                  $10/tháng,
                </p>
              </div>
              <p className="mc-pl-1 mc-text--center mc-opacity--hinted">
              Thanh toán hàng năm.
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex mc-px-4 flex-column">
          <a
            className="c-button c-button--primary c-button--md mc-mb-3"
            href="#"
          >
            Tải ứng dụng
          </a>
        </div>
      </div>
      <div className="d-none d-md-block">
        <p className="mc-text--center mc-pt-10 mc-pb-8 mc-text-h1">
        Bắt đầu hành trình của bạn ngay hôm nay.
        </p>
        <div className="mc-mb-8 mc-text--center">
          <div className="flex-column flex-md-row justify-content-center d-flex">
            <p className="mc-text--center mc-opacity--hinted">
            Chọn từ 3 gói dịch vụ.
            </p>
            <div className="justify-content-center d-flex flex-row">
              <p className="mc-pl-1 mc-text--center mc-opacity--hinted">
                Bắt đầu chỉ với
              </p>
              <div className="tracking-container ">
                <p className="mc-pl-1 mc-text--center mc-text--bold">
                  $10/tháng,
                </p>
              </div>
              <p className="mc-pl-1 mc-text--center mc-opacity--hinted">
               Thanh toán hằng năm.
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-center">
          <a
            className="c-button c-button--primary c-button--md mc-mr-3"
            href="/download-app"
          >
            Tải ứng dụng
          </a>
         
        </div>
      </div>
    </div>
  </section>
  <div className="tracking-container ">
    <section data-testid="business-section" className="container mc-my-10 ">
      <div className="row">
        <div className="col-12 d-flex justify-content-center align-items-center flex-column flex-md-row mc-p-0 mc-m-0 flex-md-nowrap BusinessBanner">
          <div className="mc-bg-neutral-900 mc-corners--md BusinessBanner_background__P_KbA" />
          <div className="position-relative mc-corners--lg mc-overflow--hidden BusinessBanner_img__FnmMa">
            <span
              style={{
                boxSizing: "border-box",
                display: "block",
                overflow: "hidden",
                width: "initial",
                height: "initial",
                background: "none",
                opacity: 1,
                border: 0,
                margin: 0,
                padding: 0,
                position: "absolute",
                inset: 0
              }}
            >
              <img
                alt=""
                data-testid="maw-banner-l&d-img"
                sizes="100vw"
                src="https://res.cloudinary.com/dhdfr7p4h/image/upload/v1715072344/photo1715070438_oiasg7.jpg"
                decoding="async"
                data-nimg="fill"
                style={{
                  position: "absolute",
                  inset: 0,
                  boxSizing: "border-box",
                  padding: 0,
                  border: "none",
                  margin: "auto",
                  display: "block",
                  width: 0,
                  height: 0,
                  minWidth: "100%",
                  maxWidth: "100%",
                  minHeight: "100%",
                  maxHeight: "100%",
                  objectFit: "cover"
                }}
              />
            </span>
          </div>
          <div className="mc-mx-md-9 mc-mx-4 mc-mr-xl-0 d-flex flex-column align-items-center align-items-md-start mc-my-6 mc-my-md-0 BusinessBanner_info__GIVcx">
            <div className="position-relative BusinessBanner_logo__eP0CK">
              <span
                style={{
                  boxSizing: "border-box",
                  display: "block",
                  overflow: "hidden",
                  width: "initial",
                  height: "initial",
                  background: "none",
                  opacity: 1,
                  border: 0,
                  margin: 0,
                  padding: 0,
                  position: "absolute",
                  inset: 0
                }}
              >
                <img
                  alt="MasterClass Logo"
                  data-testid="maw-banner-logo"
                  sizes="100vw"
                  src="https://res.cloudinary.com/dhdfr7p4h/image/upload/v1715072344/photo1715070438_oiasg7.jpg"
                  decoding="async"
                  data-nimg="fill"
                  style={{
                    position: "absolute",
                    inset: 0,
                    boxSizing: "border-box",
                    padding: 0,
                    border: "none",
                    margin: "auto",
                    display: "block",
                    width: 0,
                    height: 0,
                    minWidth: "100%",
                    maxWidth: "100%",
                    minHeight: "100%",
                    maxHeight: "100%",
                    objectFit: "cover"
                  }}
                />
              </span>
            </div>
            <h1 className="mc-text-h2 mc-text--uppercase mc-text-d1 mc-mt-2 mc-mb-6 BusinessBanner_title__zdFui ">
            Nâng cao trình độ team của bạn
            </h1>
            <p className="mc-text--2-lines-max mc-opacity--hinted mc-text--center mc-text-md--left BusinessBanner_description__ANrJV">
            Xem tại sao các tổ chức hàng đầu tin dùng Ikigai Coach cho việc học tập và phát triển.
            </p>
            <div className="mc-mt-8 d-flex flex-md-row flex-column align-items-center">
              <a
                className="c-button c-button--primary c-button--md mc-mr-4"
                href="/download-app"
              >
                Tải ứng dụng
              </a>
              {/* <a
                className="c-button c-button--link c-button--md mc-mt-4 mc-mt-md-0"
                href="#"
              >
                Tìm hiểu thêm
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={25}
                  fill="none"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  role="img"
                  className="mc-icon mc-icon--2 mc-ml-3"
                >
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M12.87 2.825a1.094 1.094 0 0 0-1.557 0 1.118 1.118 0 0 0 0 1.572l6.961 7.025H3.101c-.608 0-1.101.498-1.101 1.111 0 .614.493 1.112 1.1 1.112h15.108l-6.895 6.958a1.119 1.119 0 0 0 0 1.572c.43.433 1.127.433 1.557 0l8.808-8.89c.43-.433.43-1.137 0-1.57z"
                    clipRule="evenodd"
                  />
                </svg>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</>
<>
  <section className="container mc-my-12 mc-pb-6" data-testid="stories-section">
    <h2 className="mc-text-h2 mc-mb-10 mc-text--center">
      Chia sẻ của học viên
    </h2>
   <FeedbackComponent />
  </section>
  <div className="lazyload-wrapper " style={{ display: "none" }}>
    <section className="mc-my-12" data-testid="category-samples-section">
      <div className=" d-md-block">
        <div className="mc-tile mc-tile--1x1">
          <div className="mc-tile__content content">
            <div className="mc-opacity--muted mc-tile__component mc-tile-video mc-tile-video--show">
              <div
                className="mc-tile-video__video"
                style={{ pointerEvents: "none" }}
              >
                <div className="mc-video mc-h-100 mc-w-100 mc-video--fit-cover mc-video--state-playing mc-video--16x9 mc-h-100">
                  <video
                    className="mc-video__video"
                    autoPlay="true"
                    src="https://res.cloudinary.com/dhdfr7p4h/video/upload/v1714725768/1061926687-preview_jvnvni.mp4"
                  ></video>
                </div>
              </div>
            </div>
            <div className="CategoriesTile_StickyCaption__M09Md  mc-tile__component mc-tile-caption mc-tile-caption--x-left mc-tile-caption--y-top">
              <div className="mc-tile-caption__content mc-p-3">
                <div className="container">
                  <h2 className="mc-text-h2 mc-pr-9">
                    Choose a category to watch a class highlight
                  </h2>
                  <div className="mc-mt-4">
                    <div className="mc-mt-3">
                      <button
                        type="button"
                        className="c-button c-button--link c-button--md mc-text--bare-link-parent mc-text--capitalize mc-p-0"
                      >
                        <span className="mc-text--bare-link mc-text-h4">
                          Acting &amp; Performing Arts
                        </span>
                      </button>
                    </div>
                    <div className="mc-mt-3">
                      <button
                        type="button"
                        className="c-button c-button--link c-button--md mc-text--bare-link-parent mc-text--capitalize mc-p-0"
                      >
                        <span className="mc-text--bare-link mc-text-h4">
                          Business &amp; Entrepreneurship
                        </span>
                      </button>
                    </div>
                    <div className="mc-mt-3">
                      <button
                        type="button"
                        className="c-button c-button--link c-button--md mc-text--bare-link-parent mc-text--capitalize mc-p-0"
                      >
                        <span className="mc-text--bare-link mc-text-h4">
                          Community &amp; Government
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="CategoriesTile_StickyCaption__M09Md mc-py-6 mc-tile__component mc-tile-caption mc-tile-caption--x-right mc-tile-caption--y-top">
              <div className="mc-tile-caption__content mc-p-3">
                <button
                  type="button"
                  className="c-button c-button--tertiary c-button--md mc-corners--circle mc-p-3"
                  aria-label="Pause background video"
                >
                  <svg
                    width="2em"
                    height="2em"
                    viewBox="0 0 24 24"
                    fill="none"
                    aria-hidden="true"
                    className="mc-icon"
                    role="img"
                  >
                    <path d="M7.5 17.25V6.75H9v10.5H7.5z" fill="currentColor" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.75 6.75A.75.75 0 017.5 6H9a.75.75 0 01.75.75v10.5A.75.75 0 019 18H7.5a.75.75 0 01-.75-.75V6.75z"
                      fill="currentColor"
                    />
                    <path d="M15 17.25V6.75h1.5v10.5H15z" fill="currentColor" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.25 6.75A.75.75 0 0115 6h1.5a.75.75 0 01.75.75v10.5a.75.75 0 01-.75.75H15a.75.75 0 01-.75-.75V6.75z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <section
    className="container mc-my-12 mc-pt-4 position-relative"
    data-testid="email-capture"
    id="email-capture"
  >
    <div className="mc-theme-dark mc-bg-dark">
      <div className="position-absolute EmailCapture_thankYou__k2cy_ EmailCapture_hidden__fCpKE"></div>
      <div className="">
        <form className="row flex-column flex-md-row">
          <div className="col-md-4 d-flex flex-column justify-content-center mc-text--center mc-text-md--left">
            <h2 className="mc-text-h4">Học thử lớp học của chúng tôi</h2>
            <p className="mc-opacity--muted mc-my-4">
            Điền email của bạn và chúng tôi sẽ gửi cho bạn một số mẫu lớp học mà chúng tôi yêu thích.
            </p>
          </div>
          <div className="col-md-5 offset-md-1">
            <div className="mc-form-group mc-form-group--default">
              <div className="row no-gutters justify-content-between align-items-center">
                <div className="col align-self-end">
                  <label
                    htmlFor="email"
                    className="d-block mc-text-h8 mc-text--left mc-mb-1"
                  >
                    Email
                  </label>
                </div>
                <div className="col-12">
                  <div className="">
                    <div className="mc-form-input mc-form-element mc-form-element--default mc-form-element--md">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        className="mc-form-element__element"
                        aria-describedby="email-help-text email-info-text"
                        autoComplete="email"
                        placeholder="Nhập địa chỉ Email"
                        aria-invalid="true"
                        defaultValue=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col align-self-start">
                  <p
                    id="email-help-text"
                    className="mc-text-x-small mc-opacity--muted mc-text--left mc-mt-1"
                  />
                </div>
                <div className="col-auto align-self-start mc-ml-auto" />
              </div>
            </div>
            <div className="mc-form-group mc-form-group--default">
              <div className="row no-gutters justify-content-between align-items-center">
                <div className="col align-self-end" />
                <div className="col-12">
                  {/* <div className="">
                    <div className="mc-mt-2 align-items-center mc-text-x-small mc-input-checkbox">
                      <input
                        type="checkbox"
                        name="terms"
                        id="terms"
                        className="mc-input-checkbox__realbox"
                        aria-checked="false"
                        aria-labelledby="terms-label"
                        readOnly=""
                      />
                      <span className="mc-input-checkbox__fauxbox mc-mr-3 flex-shrink-0" />
                      <label id="terms-label">
                        <label className="mc-opacity--muted">
                          I agree to receive email updates
                        </label>
                      </label>
                    </div>
                  </div> */}
                </div>
                <div className="col align-self-start">
                  <p
                    id="terms-help-text"
                    className="mc-text-x-small mc-opacity--muted mc-text--left mc-mt-1"
                  />
                </div>
                <div className="col-auto align-self-start mc-ml-auto" />
              </div>
            </div>
            <p className="mc-mt-2 mc-text-x-small mc-opacity--muted mc-mb-4 mc-text-small">
              
Bằng cách chia sẻ địa chỉ email của bạn, bạn đồng ý với
              <a
                className="mc-text--link"
                href="https://docs.ikigai.vn/term-and-conditions"
                target="_blank"
                rel="noreferrer"
              >
                Điều khoản Dịch vụ{" "}
               </a>
               {" "} và
              <a
                className="mc-text--link"
                href="https://docs.ikigai.vn/chinh-sach-bao-mat-thong-tin-ca-nhan"
                target="_blank"
                rel="noreferrer"
              >
                {" "}Chính sách Bảo mật
              </a>{" "}
               của chúng tôi.
            </p>
          </div>
          <div className="col-md-2">
            <button
              type="submit"
              className="c-button c-button--full-width c-button--tertiary c-button--md mc-mt-md-5"
            >
              Gửi
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
</>
<FAQComponent />
<FooterComponent/>

    </div>
   </>
  );
}

export default Home;
