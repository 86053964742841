import CollapseComponent from "./Collapse";

function FAQComponent() {
    const data = [
        {
            "title": "Ikigai Coach là gì?",
            "description": "IKIGAI Coach là một nền tảng kết nối học tập chuyên về các lĩnh vực như chăm sóc sức khỏe, nâng cao hành trình cuộc sống, mở khoá tiềm năng nghề nghiệp, trở thành một nhà đầu tư tài chính, và cải thiện kỹ năng kinh doanh. Ứng dụng này cung cấp các khoá học chất lượng cao và các buổi livestream với các chuyên gia hàng đầu trong các lĩnh vực. "
        },
        {
            "title": "Làm thế nào để tham gia các buổi livestream trên IKIGAI Coach?",
            "description": "Để tham gia các buổi livestream, bạn chỉ cần tải ứng dụng IKIGAI Coach, đăng ký tài khoản và chọn mục livestream. Bạn sẽ tìm thấy lịch trình các buổi livestream sắp tới và có thể tham gia theo thời gian quy định. Ngoài ra, bạn cũng có thể nghe các podcast từ các chuyên gia để nâng cao kiến thức và kỹ năng của mình."
        },
        {
            "title": " IKIGAI Coach có cung cấp chương trình affiliate không?",
            "description": "Có, IKIGAI Coach cung cấp chương trình affiliate cho người dùng. Bạn có thể đăng tải và chia sẻ các khoá học trên nền tảng này. Mỗi khi có người đăng ký khoá học thông qua liên kết affiliate của bạn, bạn sẽ nhận được hoa hồng. Đây là cơ hội tốt để kiếm thêm thu nhập bằng cách chia sẻ các khoá học hữu ích với bạn bè và cộng đồng của bạn."
        },
       
    ]
    return ( 

<section className="container mc-my-12" data-testid="faq-section" id="faq">
  <h2 className="mc-text-h2  mc-text--center">Câu hỏi thường gặp</h2>
  <div className="row">
    <div className="col-md-8 offset-md-2">
      <div data-accordion-component="Accordion" className="accordion">
        <div className="mc-mb-6 mc-mt-9">
          {/* <h3 className="mc-opacity--hinted mc-text--bold mc-mb-4">General</h3> */}
         {
            data.map((key, index) => (
                <div key={index}>
                    <CollapseComponent item={key}/>
                </div>
            ))
         }
        </div>
     
      </div>
    </div>
  </div>
</section>
     );
}

export default FAQComponent;