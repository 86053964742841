
import Slider from "react-slick";
function FeedbackComponent() {
    var settings = {
      // className: "center",
    // centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
    arrows: false
      };
    return ( 
        <div className="mc-mt-10" id="feed-back">
        <div className=" d-md-block">
         
    <Slider {...settings}>


         <div className="d-md-flex mc-mx-2 mc-mb-3 align-items-center justify-content-center flex-col-item">
                    <div className="StudentStory mc-bg-gray-200 d-flex flex-column mc-p-8 justify-content-between mc-corners-bottom-left--sm mc-corners-bottom-right--sm">
                      <blockquote>
                      Khóa học "DISC Thấu Hiểu Người Đối Diện" trên Ikigai Coach thật sự hữu ích và dễ hiểu. Chỉ trong thời gian ngắn, tôi đã nắm bắt được cách phân loại và nhận diện các kiểu tính cách khác nhau, từ đó cải thiện kỹ năng giao tiếp và tương tác với mọi người xung quanh. Nội dung được trình bày rõ ràng, có tính ứng dụng cao, và giảng viên rất tận tâm. Sau khi học xong, tôi cảm thấy tự tin hơn trong việc xây dựng mối quan hệ cả trong công việc lẫn cuộc sống cá nhân.
                      </blockquote>
                      <div className="mc-text-small">
                        <label className="mc-text--bold">Dương Dương</label>
                        <label className="mc-ml-2 mc-opacity--muted">
                          Cầu Giấy, Hà Nội
                        </label>
                      </div>
                    </div>
                    <div className="mc-corners--sm mc-overflow--hidden StudentStory_imageContainer___om4r">
                      <div className="mc-tile mc-tile--3x4">
                        <div className="mc-tile__content content">
                          <span
                            style={{
                              boxSizing: "border-box",
                              display: "block",
                              overflow: "hidden",
                              width: "initial",
                              height: "initial",
                              background: "none",
                              opacity: 1,
                              border: 0,
                              margin: 0,
                              padding: 0,
                              position: "absolute",
                              inset: 0
                            }}
                          >
                            <img
                              alt=""
                              image="[object Object]"
                              src="https://files.exam24h.com/upload/2024/08/08_1723113219167/66335f361f438f1d907e9646-1723113219166-thumbnail-DSC02150.jpg"
                              decoding="async"
                              data-nimg="fill"
                              className="mc-tile-image"
                              sizes="100vw"
                              style={{
                                position: "absolute",
                                inset: 0,
                                boxSizing: "border-box",
                                padding: 0,
                                border: "none",
                                margin: "auto",
                                display: "block",
                                width: 0,
                                height: 0,
                                minWidth: "100%",
                                maxWidth: "100%",
                                minHeight: "100%",
                                maxHeight: "100%"
                              }}
                            />
                            <noscript />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

         <div className="d-md-flex mc-mx-2 mc-mb-3 align-items-center justify-content-center flex-col-item">
                    <div className="StudentStory mc-bg-gray-200 d-flex flex-column mc-p-8 justify-content-between mc-corners-bottom-left--sm mc-corners-bottom-right--sm">
                      <blockquote>
                      Tôi đã tham gia khóa học "Map For Success" trên Ikigai Coach và phải nói rằng đây là một trải nghiệm đáng giá. Khóa học cung cấp một lộ trình rõ ràng và chi tiết để xác định và đạt được mục tiêu cá nhân cũng như nghề nghiệp. Từ những bài giảng chất lượng cao đến các bài tập thực hành cụ thể, tất cả đều được thiết kế một cách khoa học và dễ hiểu.
                      </blockquote>
                      <div className="mc-text-small">
                        <label className="mc-text--bold">Trần Hoài</label>
                        <label className="mc-ml-2 mc-opacity--muted">
                         TP. Bắc Giang
                        </label>
                      </div>
                    </div>
                    <div className="mc-corners--sm mc-overflow--hidden StudentStory_imageContainer___om4r">
                      <div className="mc-tile mc-tile--3x4">
                        <div className="mc-tile__content content">
                          <span
                            style={{
                              boxSizing: "border-box",
                              display: "block",
                              overflow: "hidden",
                              width: "initial",
                              height: "initial",
                              background: "none",
                              opacity: 1,
                              border: 0,
                              margin: 0,
                              padding: 0,
                              position: "absolute",
                              inset: 0
                            }}
                          >
                            <img
                              alt=""
                              image="[object Object]"
                              src="https://files.exam24h.com/upload/2024/08/08_1723113315314/66335f361f438f1d907e9646-1723113315314-thumbnail-DSC02317.jpg"
                              decoding="async"
                              data-nimg="fill"
                              className="mc-tile-image"
                              sizes="100vw"
                             style={{
                                position: "absolute",
                                inset: 0,
                                boxSizing: "border-box",
                                padding: 0,
                                border: "none",
                                margin: "auto",
                                display: "block",
                                width: 0,
                                height: 0,
                                minWidth: "100%",
                                maxWidth: "100%",
                                minHeight: "100%",
                                maxHeight: "100%"
                              }}
                            />
                            <noscript />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
    </Slider>
                
        </div>
      </div>
     );
}

export default FeedbackComponent;
