import { useEffect } from "react";
import logo from "../../ikigaicoach.svg";
import { useNavigate } from 'react-router-dom';
function HeaderComponent({ currentLang }) {
  const changeLang = (lang) => {
    const selectElement = document
      .getElementById(":0.targetLanguage")
      .querySelector("select");

    // Tạo sự kiện click
    const clickEvent = new Event("click", { bubbles: true });

    // Kích hoạt sự kiện click trên phần tử select
    selectElement.dispatchEvent(clickEvent);

    // Sau khi đã kích hoạt sự kiện click, chọn option có giá trị là 'ja'
    const optionToSelect = selectElement.querySelector(
      `option[value="${lang}"]`
    );
    if (optionToSelect) {
      optionToSelect.selected = true; // Chọn option này
      // Để cập nhật giao diện nếu cần thiết
      selectElement.dispatchEvent(new Event("change", { bubbles: true }));
      setTimeout(() => {
        window.location.href = "";
      }, 150);
    }
  };
  const navigate = useNavigate();

  const handleLoginClick = () => {
    const token = sessionStorage.getItem('coachAuth');
    if (token) {
      window.location.href = 'https://web.ikigaicoach.net/dashboard/community/';
    } else {
      window.location.href = 'https://web.ikigaicoach.net/auth/jwt/sign-in/';
    }
  };

  const handleRegisterClick = () => {
    const token = sessionStorage.getItem('coachAuth');
    if (token) {
      window.location.href = 'https://web.ikigaicoach.net/dashboard/community/';
    } else {
      window.location.href = 'https://web.ikigaicoach.net/auth/jwt/sign-up/';
    }
  };

  return (
    <header className="mc-background--color-dark mc-theme-dark mc-page__header mc-theme-dark ">
      <div className="container">
        <div className="Navigation_height__DbhgG flex-nowrap row no-gutters-vertical align-items-center justify-content-between">
          <div className="col-auto d-flex align-items-center ">
            <a
              className="d-none d-sm-flex mc-sr-only mc-sr-only-focusable mc-text-h7 mc-mr-4"
              href="#mc-main"
            >
              Skip To Main Content
            </a>
            <a href="/">
              <span className="mc-sr-only">MasterClass logo</span>
              <div className="d-flex">
                <div className="d-flex">
                  <img src={logo} />
                </div>
              </div>
            </a>
          </div>
          <div className="col-auto d-flex align-items-center flex-grow-1 flex-shrink-1 justify-content-end justify-content-sm-start">


            <div className="d-none d-sm-flex flex-grow-1">
              <div className="mc-search-input Search_searchInput__Fx8GF  ">
                <div className="d-block d-xl-none ">
                  <label htmlFor="searchInput" className="mc-sr-only">
                    Search
                  </label>
                  <div className="mc-form-input mc-form-element mc-form-element--default mc-form-element--md">
                    <div className="mc-form-prepend">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={25}
                        fill="none"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        role="img"
                        className="mc-icon mc-icon--md mc-ml-4 mc-mr-2 mc-my-2 mc-text-color--light"
                      >
                        <path
                          fill="currentColor"
                          fillRule="evenodd"
                          d="M10.519 4.722a6.296 6.296 0 1 0 0 12.593 6.296 6.296 0 0 0 0-12.593M2 11.018a8.519 8.519 0 1 1 17.037 0 8.519 8.519 0 0 1-17.037 0"
                          clipRule="evenodd"
                        />
                        <path
                          fill="currentColor"
                          fillRule="evenodd"
                          d="M15.66 16.157a1.111 1.111 0 0 1 1.57 0l4.445 4.445a1.111 1.111 0 0 1-1.571 1.571l-4.445-4.444a1.111 1.111 0 0 1 0-1.572"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      id="searchInput"
                      type="text"
                      className="mc-form-element__element"
                      placeholder="Search"
                      aria-invalid="false"
                      defaultValue=""
                    />
                    <div className="mc-form-append" />
                  </div>
                </div>
                <div className="d-none d-xl-block undefined">
                  <label htmlFor="searchInput" className="mc-sr-only">
                    Hôm nay bạn muốn học gì?
                  </label>
                  <div className="mc-form-input mc-form-element mc-form-element--default mc-form-element--md">
                    <div className="mc-form-prepend">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={25}
                        fill="none"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        role="img"
                        className="mc-icon mc-icon--md mc-ml-4 mc-mr-2 mc-my-2 mc-text-color--light"
                      >
                        <path
                          fill="currentColor"
                          fillRule="evenodd"
                          d="M10.519 4.722a6.296 6.296 0 1 0 0 12.593 6.296 6.296 0 0 0 0-12.593M2 11.018a8.519 8.519 0 1 1 17.037 0 8.519 8.519 0 0 1-17.037 0"
                          clipRule="evenodd"
                        />
                        <path
                          fill="currentColor"
                          fillRule="evenodd"
                          d="M15.66 16.157a1.111 1.111 0 0 1 1.57 0l4.445 4.445a1.111 1.111 0 0 1-1.571 1.571l-4.445-4.444a1.111 1.111 0 0 1 0-1.572"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      id="searchInput"
                      type="text"
                      className="mc-form-element__element"
                      placeholder="Hôm nay bạn muốn học gì?"
                      aria-invalid="false"
                      defaultValue=""
                    />
                    <div className="mc-form-append" />
                  </div>
                </div>
              </div>
              <div />
            </div>
          </div>
          <div className="col-auto d-flex align-items-center" >
            <a
              className="mc-ml-4 d-none d-md-block"
              href="https://www.ikigai.vn/mentor-ikigaicoach"
            >
              Mentor
            </a>
            <div className="mc-ml-4 dropdown">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                  cursor: "pointer",
                }}
                className="dropbtn"
              >
                <img
                  src={
                    currentLang == "ja"
                      ? "./ja.png"
                      : currentLang == "vi"
                        ? "https://ieltshunter.io/vie.svg"
                        : "https://ieltshunter.io/eng.svg"
                  }
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  fill="white"
                >
                  <path
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth="1.5"
                    d="M19.92 8.95l-6.52 6.52c-.77.77-2.03.77-2.8 0L4.08 8.95"
                  />
                </svg>
              </div>
              <div className="dropdown-content">

                <span className="arrow-up"></span>
                <div onClick={() => changeLang("vi")}><img src='https://ieltshunter.io/vie.svg' style={{ width: 20, height: 20 }} />
                  <p>Tiếng Việt</p>
                </div>
                <div onClick={() => changeLang("en")}><img src='https://ieltshunter.io/eng.svg' style={{ width: 20, height: 20 }} />
                  <p>Tiếng Anh</p>
                </div>
                <div onClick={() => changeLang("ja")}><img src='./ja.png' style={{ width: 20, height: 20 }} />
                  <p>Tiếng Nhật</p>
                </div>
              </div>
            </div>

            <div className="d-none d-md-block"></div>

            <a
              type="button"
              className="c-button c-button--outline c-button--md mc-ml-4  d-none d-md-block"
              onClick={handleLoginClick}
            >
              Đăng nhập
            </a>
            <a
              type="button"
              className="c-button c-button--primary c-button--md mc-ml-4 button-register"
              onClick={handleRegisterClick}
            >
              Đăng ký
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderComponent;
